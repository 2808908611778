import React from "react";

const RightArrow = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2004 9H0.900391"
        stroke="#191818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.09961 16.2L15.9707 9.49157C16.0427 9.43014 16.1003 9.3546 16.1397 9.26996C16.1792 9.18532 16.1996 9.09352 16.1996 9.00066C16.1996 8.90779 16.1792 8.81599 16.1397 8.73135C16.1003 8.64671 16.0427 8.57117 15.9707 8.50975L8.09961 1.8"
        stroke="#191818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RightArrow;
