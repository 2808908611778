import React from "react";
import Image from "gatsby-image";

const TextAndImageBlock = ({ sliceItems }) => {
  return (
    <>
      {sliceItems &&
        sliceItems.map((item, index) => {
          let imagePosition =
            item.image_position === "Left" ? "flex-row-reverse" : "";

          return (
            <div key={index} className="px-gs">
              <div className={`${imagePosition} xl:flex gap-x-10 mt-12`}>
                <div>
                  {item.subheading?.text && (
                    <h2 className="font-normal text-2xl">
                      {item.subheading.text}
                    </h2>
                  )}
                  {item.body1?.html && (
                    <div
                      className="mt-4 md:mt-6 text-base md:text-lg lg:text-xl leading-relaxed rte"
                      dangerouslySetInnerHTML={{
                        __html: item.body1.html
                      }}
                    />
                  )}
                </div>

                {item.image?.fluid && (
                  <div className="flex-shrink-0 w-full pt-6 xl:pt-0 xl:w-1/2">
                    <Image
                      placeholderStyle={{ display: "none" }}
                      fluid={{
                        ...item.image?.fluid,
                        aspectRatio: 1.33
                      }}
                      objectCover
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default TextAndImageBlock;
