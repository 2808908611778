import React from "react";
import Image from "gatsby-image";

const DualImageBlock = ({ primary }) => {
  return (
    <div className="lg:flex space-y-4 lg:space-y-0 gap-x-6 px-gs mt-10">
      {primary?.first_image && (
        <div className="w-full lg:w-1/2">
          <Image
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...primary?.first_image.fluid,
              aspectRatio: 1
            }}
            objectCover
            loading="lazy"
          />
        </div>
      )}
      {primary?.second_image && (
        <div className="w-full lg:w-1/2">
          <Image
            placeholderStyle={{ display: "none" }}
            fluid={{
              ...primary?.second_image.fluid,
              aspectRatio: 1
            }}
            objectCover
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
};

export default DualImageBlock;
