import React from "react";

const LeftArrow = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.79961 9H17.0996"
        stroke="#191818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.90039 16.2L2.02927 9.49157C1.95734 9.43014 1.89972 9.3546 1.86026 9.26996C1.8208 9.18532 1.80039 9.09352 1.80039 9.00066C1.80039 8.90779 1.8208 8.81599 1.86026 8.73135C1.89972 8.64671 1.95734 8.57117 2.02927 8.50975L9.90039 1.8"
        stroke="#191818"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LeftArrow;
